<template>
  <div class="dizme_tm_section" id="blog">
    <div class="dizme_tm_news">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>From My Blog</span>
          <h3>Our Recent Updates, Blog, Tips, Tricks &amp; More</h3>
        </div>
        <div class="news_list">
          <ul>
            <li class="wow fadeInUp" data-wow-duration="1s">
              <div class="list_inner">
                <div class="image">
                  <img src="/img/thumbs/42-29.jpg" alt="" />
                  <div class="main" data-img-url="/img/news/1.jpg"></div>
                  <div class="date">
                    <h3>19</h3>
                    <span>Apr</span>
                  </div>
                  <a
                    class="dizme_tm_full_link"
                    href="#"
                    @click.prevent="active = 1"
                  ></a>
                </div>
                <div class="details">
                  <span class="category"
                    ><a href="#" @click.prevent="active = 1"
                      >Pizza News</a
                    ></span
                  >
                  <h3 class="title">
                    <a href="#" @click.prevent="active = 1"
                      >Soon we will make Pide – Meat Flat Bread / Pizza</a
                    >
                  </h3>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="/img/thumbs/42-29.jpg" alt="" />
                  <div class="main" data-img-url="/img/news/2.jpg"></div>
                  <div class="date">
                    <h3>18</h3>
                    <span>Apr</span>
                  </div>
                  <a
                    class="dizme_tm_full_link"
                    href="#"
                    @click.prevent="active = 2"
                  ></a>
                </div>
                <div class="details">
                  <span class="category"
                    ><a href="#" @click.prevent="active = 2">Pizza News</a></span
                  >
                  <h3 class="title">
                    <a href="#" @click.prevent="active = 2"
                      >Bar & Restaurant Expo in Las Vegas Was Largest in Five Years</a
                    >
                  </h3>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="/img/thumbs/42-29.jpg" alt="" />
                  <div class="main" data-img-url="/img/news/3.jpg"></div>
                  <div class="date">
                    <h3>23</h3>
                    <span>Dec</span>
                  </div>
                  <a
                    class="dizme_tm_full_link"
                    href="#"
                    @click.prevent="active = 3"
                  ></a>
                </div>
                <div class="details">
                  <span class="category"
                    ><a href="#" @click.prevent="active = 3"
                      >Pizza News</a
                    ></span
                  >
                  <h3 class="title">
                    <a href="#" @click.prevent="active = 3"
                      >How Chuck E. Cheese Makes Kids With Autism Feel Welcome</a
                    >
                  </h3>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="brush_1 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/news/1.png" alt="" />
      </div>
      <div class="brush_2 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/news/2.png" alt="" />
      </div>
    </div>
  </div>
  <div :class="active == 1 ? '' : 'news_hidden_details'">
    <ModalBox :close="close">
      <div class="news_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/news/1.jpg"
            style="background-image: url('img/news/1.jpg')"
          ></div>
        </div>
        <div class="details">
          <h3>Soon we will make Pide – Meat Flat Bread / Pizza</h3>
          <span><a href="#">Pizza News</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            Pide is the famous baked Turkish flat bread that comes stuffed with a variety of toppings, including cheese, sausage, spiced meat and spinach. Often described as a Turkish pizza, the greasy, gut-busting takeaway Pides sold at neon-lit corner kebab shops have long been my secret food shame. But shame no more: this delicious homemade Pide recipe trumps takeaway 10 times over – and then some!
          </p>
          <p>
            Turkish Pide has been my Secret Food Shame* for as long as I can remember. Not authentic, freshly made Pide crafted lovingly by the hands of someone’s Turkish grandma. I’m talking about the greasy, low-rent versions sold from hot glass cabinets at late night takeaway shops, filled with cheap cheese and piles of meat shaved from giant Doner Kebab punching bags turning lazily on vertical rotisseries.

It’s a bit wrong, I know, but I cannot help myself. I have always had a soft spot for bad takeaway Pide. What can I say? When you’re nursing battle wounds from a bad week at work, cheese + bread + tasty toppings will do it every every time!
          </p>
          <p>
            But like all things, there’s a time and place. Pide can be trash food but it can also be incredible, going toe-to-toe with the best artisan Italian pizzas. Made totally from scratch like today’s recipe, it’s apples and oranges to the takeaway junk – even a chronic takeaway Pide tragic like me will admit it!
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 2 ? '' : 'news_hidden_details'">
    <ModalBox :close="close">
      <div class="news_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/news/2.jpg"
            style="background-image: url('img/news/2.jpg')"
          ></div>
        </div>
        <div class="details">
          <h3>Bar & Restaurant Expo in Las Vegas Was Largest in Five Years</h3>
          <span><a href="#">Pizza News</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            The event drew 11,477 attendees, a 10% increase over 2022, and 400 exhibitors, a 52% increase compared to the previous year.
          </p>
          <p>
            The 37th annual Bar & Restaurant Expo, held March 27-29 in Las Vegas, was the largest in five years, with a total of 11,477 attendees, a 10% increase over the 2022 event.

The expo floor opened on Tuesday, March 28, with a special ribbon-cutting ceremony hosted by Food Network celebrity chef Jet Tila, who cut the ceremonial ribbon alongside Bar & Restaurant Group Vice President Tim McLucas. In addition to Tila, other celebrities, guests and chefs graced the red carpet, including Robert “Kool” Bell from Kool & The Gang; celebrity mixologist Mia Mastroianni; celebrity chef Brian Duffy; Jackie Summers, the keynote speaker and founder of JackFromBrooklyn and Sorel Liqueur; and Phil Wills, co-founder of Spirits in Motion.

Also on the red carpet, McLucas presented Shelia Bennet, executive director of CORE (Children of Restaurant Employees), the official charity partner of Bar & Restaurant Expo, with a $5,000 donation on behalf of the expo.
            line.
          </p>
          <p>
            Tila presented the opening keynote session, sharing his journey from immigrant son to world-renowned chef and the lessons he’s learned throughout his entrepreneurial career. Day two kicked off with a dual keynote session by Summers and Susan Fedroff, co-owner of Clover Club, Leyenda and MilLady, who presented critical leadership skills that apply to all stages of developing a new business.

On the expo floor, buyers had access to 400 exhibitors, a 52% increase compared to 2022, plus an expanded program of exciting live culinary demos by a team of high-profile chefs, including Duffy, Larissa DaCosta, Kevin DesChenes, and Kayla Robison, who showcased the latest food and equipment innovation that enhances revenue and the guest experience.

Guests also sampled delicious dishes that included Honolulu street garlic shrimp; Nikkei-style crispy pork belly causa; and short rib Penang curry pressed sandwiches.

“Seeing the rapid growth in participation from attendees and exhibitors this year has been amazing considering where the industry was just three years ago,” McLucas said. “New business opportunities are thriving in the industry right now, and we’re focused on supporting operators as they grow their businesses and expand their food and beverage offerings that appeal to their customers’ evolving needs.”

This year’s Bar & Restaurant Expo also featured the first-ever Japanese Food & Beverage Showcase, in which 50 exhibitors presented a mix of top Japanese products, flavors and globally renowned specialties. There was also the Restaurant Zone, a 15,000-square-foot area dedicated to restaurant equipment, food and solutions. The new Non Alc Chill Zone showcased what’s new in the fast-growing industry of non-alcoholic beverages.
          </p>
          <p>Bar & Restaurant Expo capped off the year with the 2023 Industry Excellence Awards ceremony. Mastroianni presented the awards, with categories including community leadership, bartender of the year and best hospitality training program. Find a full list of the 2023 winners here.

Kim Haasarud of Liquid Architecture was presented with the Industry Impact Award, which recognized Haasarud’s passion for the industry and her help advancing the industry in a significant way.

The 2024 Bar & Restaurant Expo will return to Las Vegas March 18-20, 2024.</p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 3 ? '' : 'news_hidden_details'">
    <ModalBox :close="close">
      <div class="news_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/news/3.jpg"
            style="background-image: url('img/news/3.jpg')"
          ></div>
        </div>
        <div class="details">
          <h3>How Chuck E. Cheese Makes Kids With Autism Feel Welcome</h3>
          <span><a href="#">Pizza News</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            The chain will celebrate World Autism Month with a nationwide Sensory Sensitive Sunday event and fundraisers for Autism Speaks in April.
          </p>
          <p>
            Sensory overload—loud noises, bright or flashing lights, crowded rooms and other stimuli—can be overwhelming for children with autism. Kid-friendly restaurants like Chuck E. Cheese aren’t always so appealing to these kids, but the chain has been working for years to make every person with autism feel more welcome.

Case in point: In celebration of World Autism Month in April, Chuck E. Cheese will host a national Sensory Sensitive Sunday event at all 460 U.S. and Canada locations on Sunday, April 30. The chain will also host a month-long fundraiser for Autism Speaks, the nonprofit organization dedicated to creating a more inclusive world for all people with autism.

As part of an extended partnership, Chuck E. Cheese will also launch autism-friendly workforce training and inclusion programs.
          </p>
          <p>
            Now in its sixth year, Chuck E. Cheese’s Sensory Sensitive Sundays program was developed in partnership with the Center for Autism and Related Disorders. Its goal is to create a quieter dining and arcade experience for kids with autism and sensory needs. Through this program, select Chuck E. Cheese fun centers open two hours early on the first Sunday of each month for sensory-sensitive fun with dimmer lights and lower volume, creating a calmer environment so that kids with sensory sensitivities can enjoy the Chuck E. Cheese experience to the fullest. A specially trained staff works to ensure every guest at each event has a safe, fun-filled visit.

Since the program was launched on World Autism Awareness Day on April 2, 2017, Chuck E. Cheese has held more than 18,000 sensory-friendly events, according to a press release.

“Our Sensory Sensitive Sunday program is the only one of its kind on a national scale and is just one of the many ways we deliver on our mission to make Chuck E. Cheese the place ‘where every kid can be a kid,’” David McKillips, CEO of CEC Entertainment, said. “In addition to our sensory-friendly events, we further our mission by fostering an inviting workplace culture through inclusive workforce education and training programs to better serve our teams, guests and communities.”
          </p>
          <p>Chuck E. Cheese says it’s a “proud employer of people with autism in roles ranging from cast members to manager levels, providing a welcoming environment for everyone, from first-time job seekers to those pursuing life-long careers.”

Chuck E. Cheese has extended its collaboration with Autism Speaks to include several strategic initiatives:</p>
<p>Workplace Inclusion Now (WIN): Autism Speaks created this employment initiative to bring together people with autism and related conditions, communities and employers to build and support an inclusive, welcoming workplace culture. In addition, Chuck E. Cheese fun centers will educate employees about autism and how to create more inclusive experiences for autistic guests, earning an Autism Friendly Designation from Autism Speaks upon completion of trainings.

Creating and sharing meaningful, collaborative content on Chuck E. Cheese channels highlighting the importance of neurodiversity inclusion.</p>
<p>Annual World Autism Month fundraisers benefiting Autism Speaks, which invests 89 cents of every dollar to funding research, advocacy, programs and services that help people with autism reach their full potential.

World Autism Month fundraisers include Cotton Candy for a Cause, where Chuck E. Cheese will donate a portion of proceeds from cotton candy sales up to $50,000 to encourage understanding and raise funds in support of people with autism. In-store guests, website visitors or app users are also invited to round up their purchase to the next dollar at checkout to donate the difference in support of creating a kinder, more inclusive world. Additionally, 10% of profits from all sales from the Chuck E. Online Shop during the month will be donated to Autism Speaks for its advocacy, support and research into causes and better interventions for autism spectrum disorder and related conditions.

“For more than five years, we’ve proudly collaborated with Chuck E. Cheese to drive understanding and inclusion for children with autism and their families,” said Keith Wargo, president and CEO of Autism Speaks. “In working together to implement a new education and training program, we’ve taken another step forward in creating more inclusive opportunities for autistic individuals throughout the lifespan, from childhood through adulthood, making a world of difference for all people with autism.”</p>
        </div>
      </div>
    </ModalBox>
  </div>
</template>

<script>
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "NewsComponent",
  components: { ModalBox },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
};
</script>
