<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_subscribe">
      <div class="container">
        <div class="inner">
          <div class="background">
            <div class="dots" data-img-url="/img/subscribe/dots.jpg"></div>
            <div class="overlay"></div>
          </div>
          <div class="content">
            <div class="left wow fadeInLeft" data-wow-duration="1s">
              <span class="subtitle">Subscribe Now</span>
              <h3 class="title">Get Our Newsletter</h3>
              <p class="text">
                Get latest news, updates, tips and trics in your inbox
              </p>
            </div>
            <div class="right wow fadeInRight" data-wow-duration="1s">
              <div class="field">





                <form action="https://romanospizzaandwings.us17.list-manage.com/subscribe/post?u=9a484e11777a214678ba1369e&amp;id=4fa0de0dab&amp;f_id=001565e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                  <p class="text">
                    Enter your Email
              </p> 
                <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
                  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 

</template>



























<script>
export default {
  name: "SubscribeComponent",
  components: {},
};
</script>
