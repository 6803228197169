<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <div class="dizme_tm_section" id="portfolio">
    <div class="dizme_tm_portfolio">
      <div class="container">
        
  






  <div class="dizme_tm_main_title" data-align="center">
          <span>Menu</span>
          <h3>Our Amazing Menu</h3>
          <p>
            We’re passionate about food and believe in using the freshest, best possible ingredients in all of our dishes. We believe in using seasonal, locally sourced produce & love working with local farmers & producers. We believe that food should be enjoyed, should be full of flavour and look great!
          </p>      
        </div> <div class="dizme_tm_main_title" data-align="center">
        <span>Please note, our menu changes every season.</span>
        </div>
  <section id="menu-3" class="wide-100 menu-section division">
				<div class="container">
<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">
<!-- Title -->
<div class="row">
  <div class="col-md-12">
    <div class="menu-3-title"><h3 class="h3-md red-color">Handcrafted Pizza</h3></div>
  </div>
</div>
<div class="row">
 <!-- LEFT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">
       <!-- MENU ITEM-1 -->
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">

           <!-- Title & Price -->
           <div class="menu-title-wrapper rel">
         
             <div class="menu-item-title"><h5 class="h5-sm">10" Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$7.99+</h5></div>
            
           </div>

           <!-- Description -->
           <div class="menu-item-desc">
             <p>Tomato sauce, mozzarella. With your choice of homemade sauce, hand made thin crust, and the Villages own special blend of cheeses.
             </p>
           </div>
         
         </a>
       </li>

       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">

           <!-- Title & Price -->
           <div class="menu-title-wrapper rel">
         
             <div class="menu-item-title"><h5 class="h5-sm">12" Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$9.99+</h5></div>
            
           </div>

           <!-- Description -->
           <div class="menu-item-desc">
             <p>Tomato sauce, mozzarella. With your choice of homemade sauce, hand made thin crust, and the Villages own special blend of cheeses.
             </p>
           </div>
         
         </a>
       </li>

     

     </ul>
   </div>
 </div>	<!-- END LEFT COLUMN -->


 <!-- RIGHT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


      <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">

           <!-- Title & Price -->
           <div class="menu-title-wrapper rel">
         
             <div class="menu-item-title"><h5 class="h5-sm">14" Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$11.99+</h5></div>
            
           </div>

           <!-- Description -->
           <div class="menu-item-desc">
             <p>Tomato sauce, mozzarella. With your choice of homemade sauce, hand made thin crust, and the Villages own special blend of cheeses.
             </p>
           </div>
         
         </a>
       </li>   <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">

           <!-- Title & Price -->
           <div class="menu-title-wrapper rel">
         
             <div class="menu-item-title"><h5 class="h5-sm">16" Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$13.99+</h5></div>
            
           </div>

           <!-- Description -->
           <div class="menu-item-desc">
             <p>Tomato sauce, mozzarella. With your choice of homemade sauce, hand made thin crust, and the Villages own special blend of cheeses.
             </p>
           </div>
         
         </a>
       </li>


             

     </ul>
   </div>
 </div>	<!-- END RIGHT COLUMN -->


</div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->
<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


<!-- Title -->
<div class="row">
  <div class="col-md-12">
    <div class="menu-3-title"><h3 class="h3-md red-color">Village Special Pizza</h3></div>
  </div>
</div>

<div class="row">


 <!-- LEFT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


       <!-- MENU ITEM-1 -->
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">

           <!-- Title & Price -->
           <div class="menu-title-wrapper rel">
         
             <div class="menu-item-title"><h5 class="h5-sm">Village Super Special Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>
            
           </div>

           <!-- Description -->
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, cheese, pepperoni, Italian sausage, ham, black and green olives, mushrooms, onions, and green peppers.
             </p>
           </div>
         
         </a>
       </li>
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Chicken Spinach Pesto Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Cheese, Bacon, chicken, spinach, tomatoes, green peppers, and onions with basil pesto sauce on a thin crust.
             </p>       </div>                 </a>       </li>
             <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Village Veggie Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, cheese, peppers, black olives, mushrooms, broccoli, onions, and eggplants.
             </p>       </div>                 </a>       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">All Canadian Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, pepperoni, mushroom, and bacon loaded with cheese.
             </p>       </div>                 </a>       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Rack and Roll Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, cheese, sausage, pickled jalapeno, and banana peppers, green peppers, and spinach.
             </p>       </div>                 </a>       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">BBQ or Buffalo Chicken Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Grilled chicken on your choice of BBQ or buffalo sauce mixed with bleu cheese or ranch.
             </p>       </div>                 </a>       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Chicken Bacon Alfredo Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Cheese, grilled chicken, and bacon on alfredo sauce.
             </p>       </div>                 </a>       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Chicken Bruschetta Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Our gourmet thin crust with tomato basil pesto, cheese, red onions, chicken breast, and fresh tomatoes.
             </p>       </div>                 </a>       </li> 
     

     </ul>
   </div>
 </div>	<!-- END LEFT COLUMN -->


 <!-- RIGHT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">London Veggie Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>From our signature tomato sauce, cheese, spinach, red onions, olives, and feta cheese.
             </p>
           </div>
         </a>
       </li><li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Village Special Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, cheese, pepperoni, Italian sausage, bacon, meatball, mushrooms, peppers, and onions.
             </p>
           </div>
         </a>
       </li>
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Aloha Special Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, cheese, Canadian bacon, and pineapple.
             </p>       </div>                 </a>       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Meat Lovers Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, cheese, pepperoni, meatball, bacon, and Italian sausage.
             </p>       </div>                 </a>       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Rim of Fire Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, cheese, pepperoni, green peppers, onions, and jalapenos.
             </p>       </div>                 </a>       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Smokin Joe Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, cheese, bull's eye BBQ sauce, mushrooms, onions, and topped with cheddar.
             </p>       </div>                 </a>       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">         
             <div class="menu-item-title"><h5 class="h5-sm">Outback Chicken Pizza</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$14.99+</h5></div>        
           </div>
           <div class="menu-item-desc">
             <p>Our signature tomato sauce, cheese, bull's eye BBQ sauce, roasted chicken breast, green peppers, and red onions topped with cheddar.
             </p>       </div>                 </a>       </li> 

             

     </ul>
   </div>
 </div>	<!-- END RIGHT COLUMN -->


</div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->
<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


<!-- Title -->
<div class="row">
  <div class="col-md-12">
    <div class="menu-3-title"><h3 class="h3-md red-color">Specials</h3></div>
  </div>
</div>

<div class="row">


 <!-- LEFT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


       <!-- MENU ITEM-1 -->
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
         
             <div class="menu-item-title"><h5 class="h5-sm">Special 1 (12" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$49.99</h5></div>
           </div>
           <!-- Description -->
           <div class="menu-item-desc">
             <p>Two 12" pizzas, 3 toppings on each, 10 wings, 4 cans of pop, and 1 free dipping sauce your choice of salad or fries.
             </p>
           </div>        
         </a>
       </li><li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
         
             <div class="menu-item-title"><h5 class="h5-sm">Special 2 (14" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$58.99</h5></div>
           </div>
           <!-- Description -->
           <div class="menu-item-desc">
             <p>Two 14" pizzas, 3 toppings on each, 10 wings, 4 cans of pop, and 1 free dipping sauce your choice of salad or fries.
             </p>
           </div>        
         </a>
       </li>



     

     </ul>
   </div>
 </div>	<!-- END LEFT COLUMN -->


 <!-- RIGHT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


       <!-- MENU ITEM-6 -->
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">

           <!-- Title & Price -->
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Special 3 (16" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$65.99</h5></div>
             
           </div>

           <!-- Description -->
           <div class="menu-item-desc">
             <p>Two 16" pizzas, 3 toppings on each, 10 wings, 4 cans of pop, and 1 free dipping sauce your choice of salad or fries.

             </p>
           </div>

         </a>
       </li>


             

     </ul>
   </div>
 </div>	<!-- END RIGHT COLUMN -->


</div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->
<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


<!-- Title -->
<div class="row">
  <div class="col-md-12">
    <div class="menu-3-title"><h3 class="h3-md red-color">Single Combos</h3></div>
  </div>
</div>

<div class="row">


 <!-- LEFT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


       <!-- MENU ITEM-1 -->
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Single Wings Deal 1 (10" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$27.99</h5></div>           
           </div>
           <div class="menu-item-desc">
             <p>10" pizza, 3 toppings, 10 chicken wings fries, and 2 can beverages.
             </p>
           </div>        
         </a>
       </li>
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Single Wings Deal 2 (12" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$33.99</h5></div>           
           </div>
           <div class="menu-item-desc">
             <p>12" pizza, 3 toppings, 10 chicken wings, fries, and 3 can beverages.
             </p>
           </div>        
         </a>
       </li>


     

     </ul>
   </div>
 </div>	<!-- END LEFT COLUMN -->


 <!-- RIGHT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">
      <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Single Wings Deal 3 (14" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$37.99</h5></div>           
           </div>
           <div class="menu-item-desc">
             <p>14" pizza, 3 toppings, 10 chicken wings, fries, and 4 can beverages.
             </p>
           </div>        
         </a>
       </li>  <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Single Wings Deal 4 (16" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$49.99</h5></div>           
           </div>
           <div class="menu-item-desc">
             <p>16" pizza, 3 toppings, 10 chicken wings, fries, and 5 can beverages.
             </p>
           </div>        
         </a>
       </li>


             

     </ul>
   </div>
 </div>	<!-- END RIGHT COLUMN -->


</div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->
<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


<!-- Title -->
<div class="row">
  <div class="col-md-12">
    <div class="menu-3-title"><h3 class="h3-md red-color">Double Combos</h3></div>
  </div>
</div>

<div class="row">


 <!-- LEFT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Double Deal 1 (12" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$26.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Two 12" pizzas, 6 toppings combined 2 dipping sauces.
             </p>
           </div> 
         </a>
       </li>
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Double Deal 2 (14" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$32.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Two 14" pizzas 6 toppings combined 2 dipping sauces.
             </p>
           </div> 
         </a>
       </li>


     

     </ul>
   </div>
 </div>	<!-- END LEFT COLUMN -->


 <!-- RIGHT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


      <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Double Deal 3 (16" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$39.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Two 16" pizzas 6 toppings combined 2 dipping sauces.</p>
           </div> 
         </a>
       </li>


             

     </ul>
   </div>
 </div>	<!-- END RIGHT COLUMN -->


</div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->






















































<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


<!-- Title -->
<div class="row">
  <div class="col-md-12">
    <div class="menu-3-title"><h3 class="h3-md red-color">Triple Combos</h3></div>
  </div>
</div>

<div class="row">


 <!-- LEFT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">
      <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Triple Deal 1 (12" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$39.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Three 12" pizzas, 9 toppings combined 3 dipping sauces.</p>
           </div> 
         </a>
       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Triple Deal 2 (14" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$49.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Three 14" pizzas, 9 toppings combined 3 dipping sauces.</p>
           </div> 
         </a>
       </li>



     

     </ul>
   </div>
 </div>	<!-- END LEFT COLUMN -->


 <!-- RIGHT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


      <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">Triple Deal 3 (16" Pizza)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$59.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Three 16" pizzas, 9 toppings combined 3 dipping sauces.</p>
           </div> 
         </a>
       </li>
             

     </ul>
   </div>
 </div>	<!-- END RIGHT COLUMN -->


</div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->

<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


<!-- Title -->
<div class="row">
  <div class="col-md-12">
    <div class="menu-3-title"><h3 class="h3-md red-color">Stix</h3></div>
  </div>
</div>

<div class="row">


 <!-- LEFT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


      <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">10" Cheese Stix</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$9.20</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>The Villages own hand selected cheeses baked into fresh and hand shaped dough then seasoned with Italian spices. Served with Village marinara.</p>
           </div> 
         </a>
       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">12" Cheese Stix</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$11.50</h5></div>          </div>
           <div class="menu-item-desc">
             <p>The Villages own hand selected cheeses baked into fresh and hand shaped dough then seasoned with Italian spices. Served with Village marinara.</p>
           </div> 
         </a>
       </li>



     

     
















     </ul>
   </div>
 </div>	<!-- END LEFT COLUMN -->


 <!-- RIGHT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


      <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">
             <div class="menu-item-title"><h5 class="h5-sm">16" Cheese Stix</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$16.10</h5></div>          </div>
           <div class="menu-item-desc">
             <p>The Villages own hand selected cheeses baked into fresh and hand shaped dough then seasoned with Italian spices. Served with Village marinara.</p>
           </div> 
         </a>
       </li>
             














     </ul>
   </div>
 </div>	<!-- END RIGHT COLUMN -->


</div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->
























<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


<!-- Title -->
<div class="row">
  <div class="col-md-12">
    <div class="menu-3-title"><h3 class="h3-md red-color">Wings</h3></div>
  </div>
</div>

<div class="row">


 <!-- LEFT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">        
             <div class="menu-item-title"><h5 class="h5-sm">Wings (5 pcs)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$8.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Our juicy chicken wings and tenders fried to perfection and coated with your choice of signature wing sauce.
             </p>
           </div>   
         </a>
       </li>
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">        
             <div class="menu-item-title"><h5 class="h5-sm">Wings (5 pcs)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$8.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Our juicy chicken wings and tenders fried to perfection and coated with your choice of signature wing sauce.
             </p>
           </div>   
         </a>
       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">        
             <div class="menu-item-title"><h5 class="h5-sm">Wings (10 pcs)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$10.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Our juicy chicken wings and tenders fried to perfection and coated with your choice of signature wing sauce.
             </p>
           </div>   
         </a>
       </li> <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">        
             <div class="menu-item-title"><h5 class="h5-sm">Wings (20 pcs)</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$19.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Our juicy chicken wings and tenders fried to perfection and coated with your choice of signature wing sauce.
             </p>
           </div>   
         </a>
       </li>


     

     
















     </ul>
   </div>
 </div>	<!-- END LEFT COLUMN -->


 <!-- RIGHT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


      <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">        
             <div class="menu-item-title"><h5 class="h5-sm">5 Piece Tenders</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$9.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Our juicy chicken wings and tenders fried to perfection and coated with your choice of signature wing sauce.
             </p>
           </div>   
         </a>
       </li>
       <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">        
             <div class="menu-item-title"><h5 class="h5-sm">10 Piece Tenders</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$16.99</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>Our juicy chicken wings and tenders fried to perfection and coated with your choice of signature wing sauce.
             </p>
           </div>   
         </a>
       </li>

             














     </ul>
   </div>
 </div>	<!-- END RIGHT COLUMN -->


</div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->





<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


<!-- Title -->
<div class="row">
  <div class="col-md-12">
    <div class="menu-3-title"><h3 class="h3-md red-color">Panzerotti</h3></div>
  </div>
</div>

<div class="row">


 <!-- LEFT COLUMN -->
 <div class="col-lg-6">
   <div class="menu-3-txt">
     <ul class="menu-3-list">


      <li class="menu-3-item">
         <a href="https://foodlondon.ca/village">
           <div class="menu-title-wrapper rel">        
             <div class="menu-item-title"><h5 class="h5-sm">12" Panzerotti</h5></div>
             <div class="menu-item-dots"></div>
             <div class="menu-item-price"><h5 class="h5-sm">$12.99+</h5></div>
           </div>
           <div class="menu-item-desc">
             <p>12" half moons of our handmade dough filled with fresh mozzarella and ricotta.
             </p>
           </div>   
         </a>
       </li>



     

     
















     </ul>
   </div>
 </div>	<!-- END LEFT COLUMN -->


 


</div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->





























					<!-- MENU-3 WRAPPER -->
			 		<div class="menu-3-wrapper">


			 			<!-- Title -->
			 			<div class="row">
			 				<div class="col-md-12">
					 			<div class="menu-3-title"><h3 class="h3-md red-color">Burgers</h3></div>
					 		</div>
					 	</div>

						<div class="row">


							<!-- LEFT COLUMN -->
							<div class="col-lg-6">
								<div class="menu-3-txt">
									<ul class="menu-3-list">


										<!-- MENU ITEM-1 -->
										<li class="menu-3-item">
											<a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
                      
													<div class="menu-item-title"><h5 class="h5-sm">Hamburger</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99+</h5></div>
													
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Includes lettuce, tomato, and mayo.
													</p>
												</div>
											
											</a>
										</li>
                    <li class="menu-3-item">
											<a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
                      
													<div class="menu-item-title"><h5 class="h5-sm">Cheeseburger</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$8.99+</h5></div>
													
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Includes lettuce, tomato, and mayo.
													</p>
												</div>
											
											</a>
										</li>


										

									

									
















									</ul>
								</div>
							</div>	<!-- END LEFT COLUMN -->


							<!-- RIGHT COLUMN -->
							<div class="col-lg-6">
								<div class="menu-3-txt">
									<ul class="menu-3-list">


									



                    <li class="menu-3-item">
											<a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
                      
													<div class="menu-item-title"><h5 class="h5-sm">Cheeseburger with Bacon</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$9.99+</h5></div>
													
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Includes lettuce, tomato, and mayo.
													</p>
												</div>
											
											</a>
										</li>
                    <li class="menu-3-item">
											<a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
                      
													<div class="menu-item-title"><h5 class="h5-sm">Double Cheeseburger</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$9.99+</h5></div>
													
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Includes lettuce, tomato, and mayo.
													</p>
												</div>
											
											</a>
										</li>










									</ul>
								</div>
							</div>	<!-- END RIGHT COLUMN -->


						</div>	<!-- End row -->
					</div>	<!-- END MENU-3 WRAPPER -->


				


					<!-- MENU-3 WRAPPER -->
			 		<div class="menu-3-wrapper">


			 			<!-- Title -->
			 			<div class="row">
			 				<div class="col-md-12">
					 			<div class="menu-3-title"><h3 class="h3-md red-color">Appetizers</h3></div>
					 		</div>
					 	</div>
					 	


						<div class="row">


							<!-- LEFT COLUMN -->
							<div class="col-lg-6">
								<div class="menu-3-txt">
									<ul class="menu-3-list">


										<!-- MENU ITEM-1 -->
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">French Fries</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$4.99</h5></div>
										
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Made from premium potatoes, this classic Straight Cut French fry is sure to be a quick and convenient crowd pleaser.</p>
												</div>
											
											</a>
										</li>

			<li class="menu-3-item">
        <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Curly Fries</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$5.99</h5></div>
									
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Made from real potatoes and simple ingredients, it’s the perfect crispy side or snack that’s never boring.</p>
												</div>
											
											</a>
										</li>

													<li class="menu-3-item">
                            <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Curly fries with cheese</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
												
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Made from real potatoes and nacho cheese, it’s the perfect crispy side or snack that’s never boring.</p>
												</div>
											
											</a>
										</li>
										
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Sweet Fries</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$5.99</h5></div>
						
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Sweet potato fries They are a good source of potassium and vitamin A</p>
												</div>
											
											</a>
										</li>
										
										
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Fried Mushroom</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$10.99</h5></div>
									
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>10 Pieces.</p>
												</div>
											
											</a>
										</li><li class="menu-3-item">
											<a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Fried Broccoli and cheese</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
											
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>6 Pieces. </p>
												</div>
											
											</a>
										</li><li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Corn Dogs</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$8.99</h5></div>
												
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>3 Pieces Battered wieners on a stick. </p>
												</div>
											
											</a>
										</li><li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Fried Shrimp</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$9.99</h5></div>
											
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>6 Pieces. Served with Tartar Sauce and lemon wedge</p>
												</div>
											
											</a>
										</li><li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Buffalo Fries</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$6.99</h5></div>
										
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Fries tossed in buffalo sauce. Served with bleu cheese sauce.</p>
												</div>
											
											</a>
										</li><li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Fried Clams</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$9.99</h5></div>
											
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Served with lemon wedge and tartar Sauce</p>
												</div>
											
											</a>
										</li><li class="menu-3-item">
											<a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Onion Rings</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$6.99</h5></div>
												
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>onion rings dipped in a fantastic batter are a crunchy and addictive appetizer to serve at parties.</p>
												</div>
											
											</a>
										</li>
									
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Garlic Bread</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$5.99+</h5></div>
							
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>This homemade garlic bread is rich, buttery, and dripping with savory garlic flavor. The whole batch will be gone in minutes!</p>
												</div>

											</a>
										</li>






									</ul>
								</div>
							</div>	<!-- END LEFT COLUMN -->


							<!-- RIGHT COLUMN -->
							<div class="col-lg-6">
								<div class="menu-3-txt">
									<ul class="menu-3-list">


										<!-- MENU ITEM-5 -->
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Cheese Fries</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$6.99</h5></div>
									
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>These cheese fries are crispy french fries topped nacho cheese. A hearty appetizer option.</p>
												</div>

											</a>
										</li>

<!-- MENU ITEM-5 -->
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Cheese fries with bacon</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
										
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>These cheese fries with bacons are crispy french fries topped nacho cheese and bacon. A hearty appetizer option.</p>
												</div>

											</a>
										</li>

										<!-- MENU ITEM-5 -->
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">
												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Curly fries with cheese and bacon</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$8.99</h5></div>
										
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Made from real potatoes, real bacon and nacho cheese, it’s the perfect crispy side or snack that’s never boring.</p>
												</div>

											</a>
										</li>
										
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Fried Cheese Ravioli</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$8.99</h5></div>
								
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>6 Pieces. </p>
												</div>

											</a>
										</li>
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Garlic Knots</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
							
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>10 Pieces. </p>
												</div>

											</a>
										</li>
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Jamaican Beef Patty</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$3.99</h5></div>
									
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>These are delicious flavored beef pastries that can be found in Jamaica and other Caribbean islands.</p>
												</div>

											</a>
										</li>
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Patato Skins with Broccoli or bacon</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99+</h5></div>
											
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>3 or 6 Piecef of Patato skins with cheese and bacon or broccoli. Served with sour cream.</p>
												</div>

											</a>
										</li>
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">
												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Mozzarella Sticks</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
												
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>6 Pieces. </p>
												</div>

											</a>
										</li>
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Chicken Nuggets</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
												
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>10 Pieces.</p>
												</div>

											</a>
										</li>
										<li class="menu-3-item">
											<a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Jumbo Chicken Tenders</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$8.99</h5></div>
													
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>5 Pieces. </p>
												</div>

											</a>
										</li>
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Jalapeno Poppers</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
										
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>6 Pieces.</p>
												</div>

											</a>
										</li>
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">
														Fried Dough with sugar</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$5.99+</h5></div>
							
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Yay! Fried dough balls! A simple bread dough with powder sugar.</p>
												</div>

											</a>
										</li>
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Mac and Cheese Bites</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
				
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>6 Pcs.</p>
												</div>

											</a>
										</li>







									</ul>
								</div>
							</div>	<!-- END RIGHT COLUMN -->


						</div>	<!-- End row -->
					</div>	<!-- END MENU-3 WRAPPER -->






























<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


	<!-- Title -->
	<div class="row">
		<div class="col-md-12">
			<div class="menu-3-title"><h3 class="h3-md red-color">Burritos</h3></div>
		</div>
	</div>
	


   <div class="row">


	   <!-- LEFT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-1 -->
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Steak Burrito</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$9.99</h5></div>
							   
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>Philly Steak, Lettuce, Tomato, Onions, Rice, Chips, Spicy Sauce in a white wrap.</p>
						   </div>
					   
					   </a>
				   </li>

<li class="menu-3-item">
  <a href="https://foodlondon.ca/village">

						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Chicken Burrito</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$9.99</h5></div>
							   
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>Grilled Chicken, Lettuce, Tomato, Onions, Rice, Chips, Spicy Sauce in a white wrap.</p>
						   </div>
					   
					   </a>
				   </li>

							   
				   

				   








			   </ul>
		   </div>
	   </div>	<!-- END LEFT COLUMN -->


	   <!-- RIGHT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-5 -->
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Ground Beef Burrito</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$9.99</h5></div>
						
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>Ground Beef, Lettuce, Tomato, Onions, Rice, Chips, Spicy Sauce in a white wrap.</p>
						   </div>

					   </a>
				   </li>












			   </ul>
		   </div>
	   </div>	<!-- END RIGHT COLUMN -->


   </div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->


	

<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


	<!-- Title -->
	<div class="row">
		<div class="col-md-12">
			<div class="menu-3-title"><h3 class="h3-md red-color">Poutines</h3></div>
		</div>
	</div>
	


   <div class="row">


	   <!-- LEFT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-1 -->
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Large Classic Poutine</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$9.99</h5></div>
			
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>Cheese Curds and gravy.</p>
						   </div>
					   
					   </a>
				   </li>
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Large Italian Poutine</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$13.99</h5></div>
				
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Italian sausage, sauteed onions, cheese curds and gravy sauce.</p>
						</div>
					
					</a>
				</li>
				<li class="menu-3-item">
          <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Large Texas Tornado Poutine</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$13.99</h5></div>
							
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Chicken, bacon, and ranch dressing., cheese curds and gravy sauce.</p>
						</div>
					
					</a>
				</li>
				<li class="menu-3-item">
          <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Large Triple Cheese Poutine</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$12.99</h5></div>
					
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Curds, mozzarella, gravy and cheddar cheese.</p>
						</div>
					
					</a>
				</li>
				<li class="menu-3-item">
					<a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Large Too Sweet Poutine</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$12.99</h5></div>
			
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>The Classic with sweet potato fries, cheese curds and gravy sauce.</p>
						</div>
					
					</a>
				</li>

				   








			   </ul>
		   </div>
	   </div>	<!-- END LEFT COLUMN -->


	   <!-- RIGHT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-5 -->
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Large Greek Poutine</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$13.99</h5></div>
				
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Chicken, onions, tomatoes, and tzatziki., cheese curds and gravy sauce.</p>
						</div>
					
					</a>
				</li>
				<li class="menu-3-item">
          <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Large Cluckmaster Poutine</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$13.99</h5></div>
						
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Chicken, mushrooms, sauteed onions, Cheese Curds and gravy.</p>
						</div>
					
					</a>
				</li>
				<li class="menu-3-item">
					<a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Large Meatlover Poutine</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$15.99</h5></div>
					
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Bacon, sausage, Pepperoni, and meatballs, cheese curds and gravy sauce.</p>
						</div>
					
					</a>
				</li>
				<li class="menu-3-item">
          <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Large Buffalo Poutine</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$12.99</h5></div>
						
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Crispy chicken in buffalo sauce, cheese curds and gravy sauce.</p>
						</div>
					
					</a>
				</li>
				<li class="menu-3-item">
          <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Large Steak Bomb Poutine</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$13.99</h5></div>
			
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Steak, mushrooms, sauteed onions, Cheese Curds and gravy.</p>
						</div>
					
					</a>
				</li>
				   










			   </ul>
		   </div>
	   </div>	<!-- END RIGHT COLUMN -->


   </div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->






























<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


	<!-- Title -->
	<div class="row">
		<div class="col-md-12">
			<div class="menu-3-title"><h3 class="h3-md red-color">Quesadilla</h3></div>
		</div>
	</div>
	


   <div class="row">


	   <!-- LEFT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-1 -->
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Cheese Quesadilla</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$7.99+</h5></div>
							 
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>White tortilla filled with Cheese. Served with Sour Cream.</p>
						   </div>
					   
					   </a>
				   </li>
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Chicken Quesadilla</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$9.99+</h5></div>
							
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>White tortilla filled with Cheese and chicken. Served with Sour Cream.</p>
						</div>
					
					</a>
				</li>

				   







			   


			   </ul>
		   </div>
	   </div>	<!-- END LEFT COLUMN -->


	   <!-- RIGHT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				<li class="menu-3-item">
					<a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Steak Quesadilla</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$9.99+</h5></div>
							
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>White tortilla filled with Cheese and philly steak. Served with Sour Cream.</p>
						</div>
					
					</a>
				</li>
				<li class="menu-3-item">
          <a href="https://foodlondon.ca/village">


						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Chicken Bacon Ranch Quesadilla</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$10.99+</h5></div>
						
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>White tortilla filled with Cheese, chicken, bacon and ranch. Served with Sour Cream.</p>
						</div>
					
					</a>
				</li>

			   









			   </ul>
		   </div>
	   </div>	<!-- END RIGHT COLUMN -->


   </div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->

<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


	<!-- Title -->
	<div class="row">
		<div class="col-md-12">
			<div class="menu-3-title"><h3 class="h3-md red-color">Ribs</h3></div>
		</div>
	</div>
	


   <div class="row">


	   <!-- LEFT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-1 -->
				   <li class="menu-3-item">
					  <a href="https://foodlondon.ca/village">


						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Ribs</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$21.99+</h5></div>
							   
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>Tender slow-cooked Full baby back ribs topped with your favorite BBQ sauce.</p>
						   </div>
					   
					   </a>
				   </li>


				   







			   


			   </ul>
		   </div>
	   </div>	<!-- END LEFT COLUMN -->


	  		   









			


   </div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->



<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


	<!-- Title -->
	<div class="row">
		<div class="col-md-12">
			<div class="menu-3-title"><h3 class="h3-md red-color">Salads</h3></div>
		</div>
	</div>
	


   <div class="row">


	   <!-- LEFT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-1 -->
				   <li class="menu-3-item">
					  <a href="https://foodlondon.ca/village">


						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Garden Salad</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
							  
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>Lettuce, tomato, onions, olives, cucumber, and garlic knots.</p>
						   </div>
					   
					   </a>
				   </li>
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">


						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Caesar Salad</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$7.99</h5></div>
							
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Romaine lettuce, croutons, and Parmesan cheese.</p>
						</div>

					</a>
				</li>
				<li class="menu-3-item">
          <a href="https://foodlondon.ca/village">


						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Chicken Caesar Salad</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$10.99</h5></div>
							
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Grill chicken, romaine lettuce, croutons, and Parmesan cheese.</p>
						</div>

					</a>
				</li>
				<li class="menu-3-item">
          <a href="https://foodlondon.ca/village">


						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Grill Chicken Salad</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$10.99</h5></div>
							
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Grill chicken, lettuce, tomato,onions, olives, cucumber, and garlic knots.</p>
						</div>

					</a>
				</li>
				<li class="menu-3-item">
					<a href="https://foodlondon.ca/village">


						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Greek Salad</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$10.99</h5></div>
							
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Lettuce, tomato, onions, olives, cucumber, feta cheese, and garlic knots.</p>
						</div>

					</a>
				</li>
				   







			   


			   </ul>
		   </div>
	   </div>	<!-- END LEFT COLUMN -->


	   <!-- RIGHT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-5 -->
				   <li class="menu-3-item">
					  <a href="https://foodlondon.ca/village">


						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Greek Salad with Chicken</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$11.99</h5></div>
							   
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>Grill chicken, lettuce, tomato, onions, olives, cucumber, feta cheese, and garlic knots</p>
						   </div>

					   </a>
				   </li>
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Chef's Salad</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$10.99</h5></div>
						
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Ham, turkey, american cheese, lettuce, tomato, onions, olives, cucumber, and garlic knots.</p>
						</div>

					</a>
				</li>
				<li class="menu-3-item">
					<a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Antipasto Salad</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$10.99</h5></div>
						
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Ham, salami, pepperoni, american cheese, lettuce, tomato, onions, olives, cucumber, and garlic knots.</p>
						</div>

					</a>
				</li>
				<li class="menu-3-item">
          <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Tuna Salad</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$10.99</h5></div>
						
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>Tuna, lettuce, tomato, onions, olives, cucumber, and garlic knots.</p>
						</div>

					</a>
				</li>
			   









			   </ul>
		   </div>
	   </div>	<!-- END RIGHT COLUMN -->


   </div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->


<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


	<!-- Title -->
	<div class="row">
		<div class="col-md-12">
			<div class="menu-3-title"><h3 class="h3-md red-color">Soups</h3></div>
		</div>
	</div>
	


   <div class="row">


	   <!-- LEFT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-1 -->
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">


						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Red Lentil Soup (24 oz)</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$6.99</h5></div>
							 
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>This tasty lentil soup has a rich, satisfying flavor and is a good source of protein. This is an easy, healthy and hearty soup...</p>
						   </div>
					   
					   </a>
				   </li>

				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Italian Wedding Soup (24 oz)</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$6.99</h5></div>
					
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>A delicious blend of all-beef meatballs, pasta, spinach and herbs marry perfectly in a savoury broth...</p>
						</div>

					</a>
				</li>
				   







			   


			   </ul>
		   </div>
	   </div>	<!-- END LEFT COLUMN -->


	   <!-- RIGHT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-5 -->
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">Chicken Noodle Soup (24oz)</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$6.99</h5></div>
							   
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>Chicken Noodle soup has always been a Canadian classic. This soup starts with fresh noodles in every order 24 oz(Large)

							   </p>
						   </div>

					   </a>
				   </li>

				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">


						<!-- Title & Price -->
						<div class="menu-title-wrapper rel">
							<div class="menu-item-title"><h5 class="h5-sm">Creamy Mushroom Soup (24 oz)</h5></div>
							<div class="menu-item-dots"></div>
							<div class="menu-item-price"><h5 class="h5-sm">$6.99</h5></div>
						
						</div>

						<!-- Description -->
						<div class="menu-item-desc">
							<p>A delicious blend of button mushrooms and real cream. 24 oz (Large)</p>
						</div>

					</a>
				</li>









			   </ul>
		   </div>
	   </div>	<!-- END RIGHT COLUMN -->


   </div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->











<!-- MENU-3 WRAPPER -->
<div class="menu-3-wrapper">


	<!-- Title -->
	<div class="row">
		<div class="col-md-12">
			<div class="menu-3-title"><h3 class="h3-md red-color">Dips</h3></div>
		</div>
	</div>
	


   <div class="row">


	   <!-- LEFT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				   <!-- MENU ITEM-1 -->
				   <li class="menu-3-item">
            <a href="https://foodlondon.ca/village">


						   <!-- Title & Price -->
						   <div class="menu-title-wrapper rel">
							   <div class="menu-item-title"><h5 class="h5-sm">BBQ Sauce Dip</h5></div>
							   <div class="menu-item-dots"></div>
							   <div class="menu-item-price"><h5 class="h5-sm">$1.49</h5></div>
							 
						   </div>

						   <!-- Description -->
						   <div class="menu-item-desc">
							   <p>Delicious BBQ Sauce</p>
						   </div>
					   
					   </a>
				   </li>

 <!-- MENU ITEM-1 -->
 <li class="menu-3-item">
	<a href="https://foodlondon.ca/village">


		<!-- Title & Price -->
		<div class="menu-title-wrapper rel">
			<div class="menu-item-title"><h5 class="h5-sm">Hot Sauce Dip</h5></div>
			<div class="menu-item-dots"></div>
			<div class="menu-item-price"><h5 class="h5-sm">$1.49</h5></div>
		
		</div>

		<!-- Description -->
		<div class="menu-item-desc">
			<p>Delicious Hot Sauce Dip</p>
		</div>
	
	</a>
</li> <!-- MENU ITEM-1 -->
<li class="menu-3-item">
	<a href="https://foodlondon.ca/village">


		<!-- Title & Price -->
		<div class="menu-title-wrapper rel">
			<div class="menu-item-title"><h5 class="h5-sm">Bleu Cheese Dip</h5></div>
			<div class="menu-item-dots"></div>
			<div class="menu-item-price"><h5 class="h5-sm">$1.99</h5></div>
			
		</div>

		<!-- Description -->
		<div class="menu-item-desc">
			<p>Delicious Bleu Cheese Dip</p>
		</div>
	
	</a>
</li> <!-- MENU ITEM-1 -->
<li class="menu-3-item">
	<a href="https://foodlondon.ca/village">


		<!-- Title & Price -->
		<div class="menu-title-wrapper rel">
			<div class="menu-item-title"><h5 class="h5-sm">Chipotle Dip</h5></div>
			<div class="menu-item-dots"></div>
			<div class="menu-item-price"><h5 class="h5-sm">$1.49</h5></div>
		
		</div>

		<!-- Description -->
		<div class="menu-item-desc">
			<p>Delicious Chipotle Dip</p>
		</div>
	
	</a>
</li>
				   







			   


			   </ul>
		   </div>
	   </div>	<!-- END LEFT COLUMN -->


	   <!-- RIGHT COLUMN -->
	   <div class="col-lg-6">
		   <div class="menu-3-txt">
			   <ul class="menu-3-list">


				    <!-- MENU ITEM-1 -->
					<li class="menu-3-item">
						<a href="https://foodlondon.ca/village">

 
							<!-- Title & Price -->
							<div class="menu-title-wrapper rel">
								<div class="menu-item-title"><h5 class="h5-sm">Ranch Dip</h5></div>
								<div class="menu-item-dots"></div>
								<div class="menu-item-price"><h5 class="h5-sm">$1.49</h5></div>
							
							</div>
 
							<!-- Description -->
							<div class="menu-item-desc">
								<p>Delicious Ranch Dip</p>
							</div>
						
						</a>
					</li> <!-- MENU ITEM-1 -->
					<li class="menu-3-item">
            <a href="https://foodlondon.ca/village">

 
							<!-- Title & Price -->
							<div class="menu-title-wrapper rel">
								<div class="menu-item-title"><h5 class="h5-sm">Chipotle Cheddar Dip</h5></div>
								<div class="menu-item-dots"></div>
								<div class="menu-item-price"><h5 class="h5-sm">$1.49</h5></div>
								
							</div>
 
							<!-- Description -->
							<div class="menu-item-desc">
								<p>Delicious Chipotle Cheddar Dip</p>
							</div>
						
						</a>
					</li> <!-- MENU ITEM-1 -->
					<li class="menu-3-item">
						<a href="https://foodlondon.ca/village">

 
							<!-- Title & Price -->
							<div class="menu-title-wrapper rel">
								<div class="menu-item-title"><h5 class="h5-sm">Garlic Dip</h5></div>
								<div class="menu-item-dots"></div>
								<div class="menu-item-price"><h5 class="h5-sm">$1.49</h5></div>
							
							</div>
 
							<!-- Description -->
							<div class="menu-item-desc">
								<p>Delicious Garlic Dip</p>
							</div>
						
						</a>
					</li> <!-- MENU ITEM-1 -->
					<li class="menu-3-item">
						<a href="https://foodlondon.ca/village">

 
							<!-- Title & Price -->
							<div class="menu-title-wrapper rel">
								<div class="menu-item-title"><h5 class="h5-sm">Gravy</h5></div>
								<div class="menu-item-dots"></div>
								<div class="menu-item-price"><h5 class="h5-sm">$1.49</h5></div>
							
							</div>
 
							<!-- Description -->
							<div class="menu-item-desc">
								<p>Delicious Gravy</p>
							</div>
						
						</a>
					</li> <!-- MENU ITEM-1 -->
			

			   









			   </ul>
		   </div>
	   </div>	<!-- END RIGHT COLUMN -->


   </div>	<!-- End row -->
</div>	<!-- END MENU-3 WRAPPER -->













































































					


					<!-- MENU-3 WRAPPER -->
			 		<div class="menu-3-wrapper">


			 			<!-- Title -->
			 			<div class="row">
			 				<div class="col-md-12">
					 			<div class="menu-3-title"><h3 class="h3-md red-color">Drinks & Desserts</h3></div>
					 		</div>
					 	</div>
					 	


						<div class="row">


							<!-- LEFT COLUMN -->
							<div class="col-lg-6">
								<div class="menu-3-txt">
									<ul class="menu-3-list">


										<!-- MENU ITEM-1 -->
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">


												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Water</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$1.49</h5></div>
													
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Naya natural spring water</p>
												</div>
											
											</a>
										</li>


										<!-- MENU ITEM-2 -->
										<li class="menu-3-item">
                      <a href="https://foodlondon.ca/village">


												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Can Soda</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$1.49</h5></div>
												
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Variety of coke and pepsi products</p>
												</div>

											</a>
										</li>


<li class="menu-3-item">
  <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">20oz Soda</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$2.89</h5></div>
													
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Variety of coke and pepsi products</p>
												</div>

											</a>
										</li>



<li class="menu-3-item">
  <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">2-lt Soda</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$3.89</h5></div>
												
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Variety of coke and pepsi products</p>
												</div>

											</a>
										</li>


<li class="menu-3-item">
  <a href="https://foodlondon.ca/village">

												<!-- Title & Price -->
												<div class="menu-title-wrapper rel">
													<div class="menu-item-title"><h5 class="h5-sm">Snapple</h5></div>
													<div class="menu-item-dots"></div>
													<div class="menu-item-price"><h5 class="h5-sm">$2.89</h5></div>
												
												</div>

												<!-- Description -->
												<div class="menu-item-desc">
													<p>Variety of Snapple products</p>
												</div>

											</a>
										</li>







									


									</ul>
								</div>
							</div>	<!-- END LEFT COLUMN -->


							


										
											
										



















						</div>	<!-- End row -->
					</div>	<!-- END MENU-3 WRAPPER -->


				</div>	   <!-- End container -->
			</section>	<!-- END MENU-3 -->

		</div>	</div>	</div>

















  
</template>
