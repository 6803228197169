<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_testimonials">
      <div class="dizme_tm_main_title" data-align="center">
        <span>Testimonials</span>
        <h3>What My Clients Say</h3>
        <p>
          Most common methods for designing websites that work well on desktop
          is responsive and adaptive design
        </p>
      </div>
      <div class="list_wrapper">
        <div class="total">
          <div class="in">
            Excellent quality of food and great service. Highly recommended.
            <swiper
              :pagination="{ clickable: true, el: '.owl-dots' }"
              :modules="modules"
              class="owl-carousel owl-theme"
            >
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    Excellent quality of food and great service. Highly recommended.
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/4.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>mesut coskun</h3>
                    <span>London</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    Got the 2 extra large pizza + 3 toppings combo. Super delicious they make amazing pizza and they are very generous with the toppings. This is always my place for nice freshly made pizza. Love the new management, super friendly and easy to deal with.
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/3.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>Kate</h3>
                    <span>London</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    Small shop, but the locals love it! You can order ahead online and pick-up, especially if you are getting a freshly baked whole pizza because that does take a while. Amazing pizza 
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/6.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>Joyce</h3>
                    <span>London</span>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="owl-dots"></div>
          </div>
          <div class="left_details">
            <div
              class="det_image one wow fadeIn"
              data-wow-duration="1s"
              data-img-url="/img/testimonials/2.jpg"
            ></div>
            <div
              class="det_image two wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-img-url="/img/testimonials/1.jpg"
            ></div>
            <div
              class="det_image three wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
              data-img-url="/img/testimonials/3.jpg"
            ></div>
            <div
              class="det_image four wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.6s"
              data-img-url="/img/testimonials/4.jpg"
            ></div>
            <span class="circle green animPulse"></span>
            <span class="circle yellow animPulse"></span>
            <span class="circle border animPulse"></span>
          </div>
          <div class="right_details">
            <div
              class="det_image one wow fadeIn"
              data-wow-duration="1s"
              data-img-url="/img/testimonials/5.jpg"
            ></div>
            <div
              class="det_image two wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-img-url="/img/testimonials/6.jpg"
            ></div>
            <div
              class="det_image three wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
              data-img-url="/img/testimonials/7.jpg"
            ></div>
            <span class="circle yellow animPulse"></span>
            <span class="circle purple animPulse"></span>
            <span class="circle border animPulse"></span>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/testimonials/1.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { testimonialIcon } from "@/svg";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "TestimonialsComponent",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      testimonialIcon,
    };
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
};
</script>
