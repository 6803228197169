<template>
  <div class="dizme_tm_section" id="service">
    <div class="dizme_tm_services">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Our Blog</span>
          <h3>What we Do for our Customers</h3>
          <p>
            Most common methods for designing websites that work well on desktop
            is responsive and adaptive design
          </p>
        </div>
        <div class="service_list">
          <ul>
            <li
              class="wow fadeInLeft"
              data-wow-duration="1s"
              @click.prevent="active = 1"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/anchor.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/1.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>Community Help</h3>
                  <span class="price">Starts from <span>$99+</span></span>
                </div>
                <div class="text">
                  <p>
                    We love making great pizza, but we don't think that's enough!
We know behind every great community there's great people and organizations. We love supporting those people in every way we can. We offer unique fundraising programs as well as sponsorship's opportunities...and of course great pizza to groups of great people!

We would love to hear about your organization or event please send us an email to villagepizza2019@gmail.com
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image"
                  src="/img/service/1.jpg"
                  alt=""
                />
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              @click.prevent="active = 2"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/physics.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/2.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>Best Valentine’s Dishes to Share at Village Pizza</h3>
                  <span class="price">Starts from <span>$14.99</span></span>
                </div>
                <div class="text">
                  <p>
                    A fun Valentine’s activity is to take your special someone out to eat. Sharing lunch or dinner together, sitting across from one another with delicious food and drink, is perhaps one of the nicest ways to spend time together.

This article is for you if you’re organizing that first Valentine’s date with someone you’ve been longing to meet or just a nice night out with your long-term partner. We recommend get delivery for nearby picnic area for a cuisine that has something for everyone, situated in a warm and lively environment. Believe it or not, what we choose when we go out to eat tells a lot about our personalities, so why not choose to show that you’re laid-back and generous by ordering something to share?
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image"
                  src="/img/service/2.jpg"
                  alt=""
                />
                <div class="service_hidden_details">
                  <div class="service_popup_informations">
                    <div class="descriptions">
                      <p>
                        A fun Valentine’s activity is to take your special someone out to eat. Sharing lunch or dinner together, sitting across from one another with delicious food and drink, is perhaps one of the nicest ways to spend time together.

                      </p>
                      <p>
                        This article is for you if you’re organizing that first Valentine’s date with someone you’ve been longing to meet or just a nice night out with your long-term partner. We recommend get delivery for nearby picnic area for a cuisine that has something for everyone, situated in a warm and lively environment. Believe it or not, what we choose when we go out to eat tells a lot about our personalities, so why not choose to show that you’re laid-back and generous by ordering something to share?
                      </p>
                     
                    </div>
                  </div>
                </div>
              </div>
            </li>
           
            
          </ul>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/service/5.png" alt="" />
      </div>
      <div class="brush_2 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/service/6.png" alt="" />
      </div>
    </div>
  </div>
  <div :class="active == 1 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/1.jpg"
            style="background-image: url('img/service/1.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>Community Help</h3>
          <span class="price">Starts from <span>$99+</span></span>
        </div>
        <div class="descriptions">
          <p>
            We love making great pizza, but we don't think that's enough!
We know behind every great community there's great people and organizations. We love supporting those people in every way we can. We offer unique fundraising programs as well as sponsorship's opportunities...and of course great pizza to groups of great people!

          </p>
          <p>
            We would love to hear about your organization or event please send us an email to villagepizza2019@gmail.com
          </p>
         
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 2 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/2.jpg"
            style="background-image: url('img/service/2.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>Best Valentine’s Dishes to Share at Village Pizza</h3>
          <span class="price">Starts from <span>$14.99+</span></span>
        </div>
        <div class="descriptions">
          <p>
            A fun Valentine’s activity is to take your special someone out to eat. Sharing lunch or dinner together, sitting across from one another with delicious food and drink, is perhaps one of the nicest ways to spend time together.

          </p>
          <p>
            This article is for you if you’re organizing that first Valentine’s date with someone you’ve been longing to meet or just a nice night out with your long-term partner. We recommend get delivery for nearby picnic area for a cuisine that has something for everyone, situated in a warm and lively environment. Believe it or not, what we choose when we go out to eat tells a lot about our personalities, so why not choose to show that you’re laid-back and generous by ordering something to share?
          </p>
         
        </div>
      </div>
    </ModalBox>
  </div>
 

</template>

<script>
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "ServiceCompoent",
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    let VanillaTilt = require("vanilla-tilt");
    VanillaTilt.init(document.querySelectorAll(".tilt-effect"), {
      maxTilt: 6,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      transition: true,
    });
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
  components: { ModalBox },
  props: {
    dark: { type: Boolean },
  },
};
</script>
